import axios from "axios";
import api from "../constants/api";
import apiFileUpload from "../constants/apiFile";
// axios.defaults.headers.common["user_type"] = "especialista";

const API_KEY = "AIzaSyBD8gCsno5Bv0YE8FonqZc6Lwp-pNDg12E";

export default class EspecialistasAPI {
  // *############################################################################################
  // *GET
  // *############################################################################################

  /**
   * This is the API Endpoint for the Variables Model. It provides records and details about
   * the variables used to visualize trends of patient vitals within the
   *  section app_doctor. 
   * 
   * @param {string} id_paciente - this is the pacient id. 
   * @param {Array<string>} id_variables - list of the ids for the variables being queried. If empty
   * all variables will be considered.
   * @param {Array<integer>} limite - this is a list of the numbers of records to be returned per variable. 
   * @param {Array<string>} rango_fecha - this is a list containing the earliest date and latest date for the records. 
   * @param {boolean} categoria - when true will return the category per variable. 
   * @param {boolean} unidad - when true will return the related unit of measurement per variable.
   *
   * @return {Object} an Axios response object from the server.
   * @property {Object} data - response provided by the server.
   * @property {number} status - HTTP status code.
   * @property {string} statusText - HTTP status message.
   * @property {Object} headers - HTTP headers in lowercase.
   * @property {Object} config - config provided to axios for the request.
   * @property {Object} request - request that generated this response.
   */
  async GetPacienteVariables(id_paciente, id_variables, limite, rango_fecha, categoria, unidad, nombre, solo_actual) {
    return axios.get(api, {
      params: {
        rt: "get_paciente_variables",
        app: "app_doctor",
        id_paciente,
        id_variables,
        limite,
        rango_fecha,
        categoria,
        unidad,
        nombre,
        solo_actual,
      }
    })
  }

  async getStandarGraphData(id_variable, genero, tipo) {
    return axios.get(api, {
      params: {
        rt: "get_standar_graph_data",
        app: "common",
        id_variable,
        genero,
        tipo,

      }
    })
  }

  async getStandarGraphDataEstaturaVsPeso(genero) {
    return axios.get(api, {
      params: {
        rt: "get_standar_graph_data_estatura_vs_peso",
        app: "common",
        genero,
      }
    })
  }

  async GetVariablesCrecimiento() {
    return axios.get(api, {
      params: {
        rt: "get_variables_crecimiento",
        app: "common",
      }
    })
  }

  async SetRecordVariable(request) {
    return axios.post(api, {
      params: {
        rt: "set_record_variable",
        app: "app_doctor",
        request
      }
    })
  }

  async SetRecordVariableEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "set_record_variable_episodio",
        app: "app_doctor",
        request
      }
    })
  }

  async setChangesVarbalesEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "set_changes_variables_episodio",
        app: "app_doctor",
        request
      }
    })
  }

  async saveNotaPersonalPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "save_notas_personales_paciente",
        app: "app_doctor",
        request
      }
    })
  }

  async deleteNotaPersonalPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "delete_nota_personal_paciente",
        app: "app_doctor",
        request
      }
    })
  }

  async setChangesVarbales(request) {
    return axios.post(api, {
      params: {
        rt: "set_changes_variables",
        app: "app_doctor",
        request
      }
    })
  }
  async updateConfigEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "update_config_episodio",
        app: "app_doctor",
        request
      }
    })
  }

  async UpdateInfoOrganizacion(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_organizacion",
        app: "app_doctor",
        request
      }
    })
  }

  async UpdateTipoPerfilOrganizacion(request) {
    return axios.post(api, {
      params: {
        rt: "update_tipo_perfil_organizacion",
        app: "app_doctor",
        request
      }
    })
  }

  async updateConfigEpisodioDoctor(request) {
    return axios.post(api, {
      params: {
        rt: "update_config_episodio_doctor",
        app: "app_doctor",
        request
      }
    })
  }

  async confirmHistoriaClinicaEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "confirm_historia_clinica_episodio",
        app: "app_doctor",
        request
      }
    })
  }

  async GetVariablesNumericasEpisodio(idEpisodio) {
    return axios.post(api, {
      params: {
        rt: "get_variables_numericas_episodio",
        app: "app_doctor",
        idEpisodio
      }
    })
  }

  async GetVariablesEpisodio(idEpisodio) {
    return axios.post(api, {
      params: {
        rt: "get_variables_episodio",
        app: "app_doctor",
        idEpisodio
      }
    })
  }

  async DeleteRecordVaraible(request) {
    return axios.post(api, {
      params: {
        rt: "delete_record_variable",
        app: "app_doctor",
        request
      }
    })
  }
  async DeleteRecordVaraibleEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "delete_record_variable_episodio",
        app: "app_doctor",
        request
      }
    })
  }
  async UpdateRecordVaraible(request) {
    return axios.post(api, {
      params: {
        rt: "update_record_variable",
        app: "app_doctor",
        request
      }
    })
  }
  async UpdateRecordVaraibleEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "update_record_variable_episodio",
        app: "app_doctor",
        request
      }
    })
  }

  async GetEspecialista(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_especialista",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetPaises(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_paises",
        app: "common",
        id_doctor,
      },
    });
  }

  async GetPacienteTratamientosList(id_paciente, pagina, limite, search, filtros) {
    return axios.get(api, {
      params: {
        rt: "get_paciente_tratamientos_list",
        app: "app_doctor",
        id_paciente,
        pagina,
        limite,
        search,
        filtros: JSON.stringify(filtros),
      },
    });
  }

  async GetPacienteEpisodiosList(id_paciente, pagina, limite, search, filtros) {
    return axios.get(api, {
      params: {
        rt: "get_paciente_episodios_list",
        app: "app_doctor",
        id_paciente,
        pagina,
        limite,
        search,
        filtros: JSON.stringify(filtros),
      },
    });
  }

  async GetNotasPersonales(id_paciente, id_doctor, fecha, search) {
    return axios.get(api, {
      params: {
        rt: "get_notas_personales_paciente",
        app: "app_doctor",
        id_paciente,
        id_doctor,
        fecha,
        search,
      },
    });
  }
  async GetPlanesAlimenticiosPacienteEspecilaista(id_paciente, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_planes_alimenticios_paciente_especialista",
        app: "app_doctor",
        id_paciente,
        id_doctor
      },
    });
  }

  async getFile(url) {

    // return axios.get("http://localhost/docca_back_end/" + url, {
    //   responseType: "blob",
    //   /* 
    //   headers: {
    //     Authorization: "Bearer <token>", // add authentication information as required by the backend APIs.
    //   },
    //    */
    // });


    return axios.get(api, {
      params: {
        rt: "get_file",
        app: "app_doctor",
        url,
      },
    });
  }

  async getEpisodiosEspecialistaPaciente(id_paciente, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_episodios_especialista_paciente",
        app: "app_doctor",
        id_paciente,
        id_doctor,
      },
    });
  }

  async getEpisodioPlanesAlimenticios(id_episodio, id_paciente, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_episodio_planes_alimenticios",
        app: "app_doctor",
        id_episodio,
        id_paciente,
        id_doctor,
      },
    });
  }

  async GetPacienteTratamientos(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_paciente_tratamientos",
        app: "common",
        id_paciente,
      },
    });
  }

  async Login(request) {
    return axios.post(api, {
      params: {
        rt: "login_especialista",
        app: "auth",
        request,
      },
    });
  }

  async GetEspecialistaByUID(uid) {
    return axios.get(api, {
      params: {
        rt: "get_user",
        app: "app_doctor",
        uid,
      },
    });
  }

  async GetPersonalByUID(uid) {
    return axios.get(api, {
      params: {
        rt: "get_user",
        app: "app_asistente",
        uid,
      },
    });
  }

  async TestFirestore() {
    return axios.get(api, {
      params: {
        rt: "test_firestore",
        app: "app_doctor",
      },
    });
  }

  async GetEventosDia(id_doctor, id_organizacion, fecha) {
    return axios.get(api, {
      params: {
        rt: "get_calendario_dia",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        fecha,
      },
    });
  }

  async FindRetraso(id_doctor, fecha) {
    return axios.get(api, {
      params: {
        rt: "find_retraso",
        app: "app_doctor",
        id_doctor,
        fecha,
      },
    });
  }

  async GetEpisodios(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_episodios",
        app: "app_doctor",
        id_doctor,
      },
    });
  }
  async GetEpisodiosTabla(id_doctor, pagina, limite, search, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_episodios_tabla",
        app: "app_doctor",
        id_doctor,
        pagina,
        limite,
        search,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetEpisodiosByFiltros(id_doctor, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_episodios_by_filtros",
        app: "app_doctor",
        id_doctor,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async testSendEmail() {
    return axios.get(api, {
      params: {
        rt: "test_send_email",
        app: "app_doctor",
      },
    });
  }

  async GetIngresos(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_ingresos",
        app: "app_doctor",
        id_doctor,
      },
    });
  }
  async GetAvisoPrivacidadPaciente(id_paciente, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_aviso_privacidad_paciente_especialista",
        app: "app_doctor",
        id_paciente,
        id_doctor,
      },
    });
  }

  async FirmaAvisoPrivacidadPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "firma_aviso_privacidad_paciente",
        app: "app_doctor",
        request,
      },
    });

  } async QuitaFirmaAvisoPrivacidadPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "quita_aviso_privacidad_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async GetSolicitudes(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_solicitudes",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }

  async GetCitas(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_citas",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }
  async GetCitasMesAnio(
    id_doctor,
    id_organizacion,
    dia,
    mes,
    anio,
    pagina,
    limite,
    search,
    cont_filtros
  ) {
    return axios.get(api, {
      params: {
        rt: "get_citas_mes_anio",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        dia,
        mes,
        anio,
        pagina,
        limite,
        search,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetCitasProximas(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_citas_proximas",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }

  async GetCitasByFiltros(id_doctor, dia, mes, anio, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_citas_by_filtros",
        app: "app_doctor",
        id_doctor,
        dia,
        mes,
        anio,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetSolicitudesDiaMesAnio(id_doctor, id_org, dia, mes, anio, id_cons) {
    return axios.get(api, {
      params: {
        rt: "get_solicitudes_dia_mes_anio",
        app: "app_doctor",
        id_doctor,
        id_org,
        dia,
        mes,
        anio,
        id_cons,
      },
    });
  }

  async GetProveedores(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_proveedores",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetProveedoresTabla(id_doctor, pagina, limite) {
    return axios.get(api, {
      params: {
        rt: "get_proveedores_tabla",
        app: "app_doctor",
        id_doctor,
        pagina,
        limite,
      },
    });
  }
  async GetProductosProveedor(id_doctor, id_prov, id_cons) {
    return axios.get(api, {
      params: {
        rt: "get_productos_proveedor",
        app: "app_doctor",
        id_doctor,
        id_prov,
        id_cons,
      },
    });
  }
  async GetProductosOrdenCompra(id_doctor, id_oc, id_cons) {
    return axios.get(api, {
      params: {
        rt: "get_productos_orden_compra",
        app: "app_doctor",
        id_doctor,
        id_oc,
        id_cons,
      },
    });
  }

  async GetEncabezadoOrdenCompra(id_doctor, id_oc) {
    return axios.get(api, {
      params: {
        rt: "get_encabezado_oc",
        app: "app_doctor",
        id_doctor,
        id_oc,
      },
    });
  }

  async GetServiciosProveedorTodos(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_servicios_proveedor_todos",
        app: "app_doctor",
        id_doctor,
      },
    });
  }
  async GetServiciosProveedorTodosTabla(id_doctor, pagina, limite, id_proveedor = "") {
    return axios.get(api, {
      params: {
        rt: "get_servicios_proveedor_todos_tabla",
        app: "app_doctor",
        id_doctor,
        pagina,
        limite,
        id_proveedor,
      },
    });
  }

  async GetNotificacionesServiciosProveedor(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_not_servicios_proveedores",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetUltimosPagosNomina(id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_ultimos_pagos_nomina_organizacion",
        app: "app_doctor",
        id_organizacion,
      },
    });
  }

  async GetPagosNomina(id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_pagos_nomina_organizacion",
        app: "app_doctor",
        id_organizacion,
      },
    });
  }

  async GetProveedoresByFiltros(id_doctor, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_proveedores_by_filtros",
        app: "app_doctor",
        id_doctor,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetPacientes(id_organizacion, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes",
        app: "app_doctor",
        id_organizacion,
        id_doctor,
      },
    });
  }

  async GetPacientesDataBasica(id_organizacion, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes_data_basica",
        app: "app_doctor",
        id_organizacion,
        id_doctor,
      },
    });
  }

  async GetPacientesCard(
    id_organizacion,
    id_doctor,
    pagina,
    limite,
    search,
    cont_filtros
  ) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes_card",
        app: "app_doctor",
        id_organizacion,
        id_doctor,
        pagina,
        limite,
        search,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetPacientesDeshabilitadosCard(
    id_organizacion,
    id_doctor,
    pagina,
    limite,
    search,
  ) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes_deshabilitados_card",
        app: "app_doctor",
        id_organizacion,
        id_doctor,
        pagina,
        limite,
        search,
      },
    });
  }

  async GetPacientesSelector(id_organizacion, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes_selector",
        app: "app_doctor",
        id_organizacion,
        id_doctor,
      },
    });
  }

  async GetPersonal(id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_personal",
        app: "app_doctor",
        id_organizacion,
      },
    });
  }
  async GetPersonalByNomina(id_organizacion, id_nomina) {
    return axios.get(api, {
      params: {
        rt: "get_personal_by_nomina",
        app: "app_doctor",
        id_organizacion,
        id_nomina,
      },
    });
  }

  async GetMotivosInasistencia() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_motivos_inasistencia",
        app: "common",
      },
    });
  }

  async ValidaCrearNomina(id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "valida_crear_nomina",
        app: "app_doctor",
        id_organizacion,
      },
    });
  }

  async GetDesgloseNomina(id_periodo_nomina) {
    return axios.get(api, {
      params: {
        rt: "get_desglose_periodo_nomina",
        app: "app_doctor",
        id_periodo_nomina,
      },
    });
  }

  async GetTrabajador(id_trabajador) {
    return axios.get(api, {
      params: {
        rt: "get_trabajador",
        app: "app_doctor",
        id_trabajador,
      },
    });
  }

  async GetProximoPeriodoCambioSalario(
    id_trabajador,
    id_organizacion,
    peridiocidad
  ) {
    return axios.get(api, {
      params: {
        rt: "get_proximo_periodo_trabajador",
        app: "app_doctor",
        id_organizacion,
        id_trabajador,
        peridiocidad,
      },
    });
  }

  async GetPacientesByFiltros(id_organizacion, id_doctor, cont_filtros) {
    // console.log(cont_filtros);
    return axios.get(api, {
      params: {
        rt: "get_pacientes_by_filtros",
        app: "app_doctor",
        id_organizacion,
        id_doctor,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetPacientesByFiltrosCard(id_organizacion, id_doctor, cont_filtros) {
    // console.log(cont_filtros);
    return axios.get(api, {
      params: {
        rt: "get_pacientes_by_filtros_card",
        app: "app_doctor",
        id_organizacion,
        id_doctor,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetArchivos(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_archivos",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetSiguientesPagosPersonal(id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_siguientes_pagos_personal",
        app: "app_doctor",
        id_organizacion,
      },
    });
  }
  async GetUltimosPagosPersonal(id_empleado) {
    return axios.get(api, {
      params: {
        rt: "get_ultimos_pagos_personal",
        app: "app_doctor",
        id_empleado,
      },
    });
  }
  async GetInasistenciasPersonal(id_empleado) {
    return axios.get(api, {
      params: {
        rt: "get_inasistencias_personal",
        app: "app_doctor",
        id_empleado,
      },
    });
  }
  async GetOtrosConceptosPersonal(id_empleado) {
    return axios.get(api, {
      params: {
        rt: "get_otros_conceptos_personal",
        app: "app_doctor",
        id_empleado,
      },
    });
  }
  async GetHistoralSalarialPersonal(id_empleado) {
    return axios.get(api, {
      params: {
        rt: "get_historial_salarial_personal",
        app: "app_doctor",
        id_empleado,
      },
    });
  }
  async GetProximasNominas(id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_proximas_nominas",
        app: "app_doctor",
        id_organizacion,
      },
    });
  }
  async GetAnterioresNominas(id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_anteriores_nominas",
        app: "app_doctor",
        id_organizacion,
      },
    });
  }
  async GetNominasPorPagar(id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_nominas_por_pagar",
        app: "app_doctor",
        id_organizacion,
      },
    });
  }
  async GetAnterioresNominasTotal(id_organizacion, pagina, limite) {
    return axios.get(api, {
      params: {
        rt: "get_anteriores_nominas_total",
        app: "app_doctor",
        id_organizacion,
        pagina,
        limite,
      },
    });
  }
  async RegenerarNominaPre(id_nomina) {
    return axios.get(api, {
      params: {
        rt: "regenerar_nomina_pre",
        app: "app_doctor",
        id_nomina,
      },
    });
  }
  async GetHorasExtraPersonal(id_empleado) {
    return axios.get(api, {
      params: {
        rt: "get_horas_extra_personal",
        app: "app_doctor",
        id_empleado,
      },
    });
  }
  async GetDetalleNominaEmpleadoId(id_nomina, id_empleado) {
    return axios.get(api, {
      params: {
        rt: "get_detalle_nomina_empleado",
        app: "app_doctor",
        id_nomina,
        id_empleado,
      },
    });
  }

  async GetCajasActuales(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_cajas_actuales",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }
  async GetCajasHistorial(
    id_doctor,
    id_organizacion,
    id_consultorio,
    pagina,
    limite
  ) {
    return axios.get(api, {
      params: {
        rt: "get_cajas_historial",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        id_consultorio,
        pagina,
        limite,
      },
    });
  }
  async GetVentaPorCobrarData(id_doctor, id_organizacion, id_venta) {
    return axios.get(api, {
      params: {
        rt: "get_venta_por_cobrar_data",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        id_venta,
      },
    });
  }
  async ValidateCreateCajas(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "validate_create_cajas",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }
  async GetDetalleNominaById(id_nomina, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_detalle_nomina_id",
        app: "app_doctor",
        id_nomina,
        id_organizacion,
      },
    });
  }

  async GetDetalleAutorizarNominaById(id_nomina, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_detalle_nomina_autorizar_id",
        app: "app_doctor",
        id_nomina,
        id_organizacion,
      },
    });
  }
  async AutorizaNomina(id_nomina) {
    return axios.get(api, {
      params: {
        rt: "autorizar_nomina",
        app: "app_doctor",
        id_nomina,
      },
    });
  }

  async UpdateAplicableConceptoNomina(id_concepto_nomina, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "update_aplicable_concepto_nomina",
        app: "app_doctor",
        id_concepto_nomina,
        id_organizacion,
      },
    });
  }

  async GetEncabezadoNominaById(id_nomina, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_encabezado_nomina_id",
        app: "app_doctor",
        id_nomina,
        id_organizacion,
      },
    });
  }

  async GetPacientesEnSeguimiento(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes_en_seguimiento",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetPaciente(id_organizacion, id_doctor, uid) {
    return axios.get(api, {
      params: {
        rt: "get_paciente_por_uid",
        app: "app_doctor",
        id_organizacion,
        id_doctor,
        uid,
      },
    });
  }

  async GetExpedientePaciente(id) {
    return axios.get(api, {
      params: {
        rt: "get_expediente_paciente",
        app: "app_doctor",
        id
      },
    });
  }

  async GetPacientesEnSeguimientoNumero(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes_en_seguimiento_numero",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetPacientesRegistradosMes(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes_registrados_mes_actual",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetPacientesRegistradosMesNumero(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes_registrados_mes_actual_numero",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetPacienteExpediente(id_doctor, id_organizacion, id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_paciente_expediente",
        app: "app_doctor",
        id_organizacion,
        id_doctor,
        id_paciente,
      },
    });
  }

  async GetConsultorio(id_doctor, id_consultorio) {
    return axios.get(api, {
      params: {
        rt: "get_consultorio",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
      },
    });
  }

  async GetAsistente(id_doctor, id_asistente) {
    return axios.get(api, {
      params: {
        rt: "get_asistente",
        app: "app_doctor",
        id_doctor,
        id_asistente,
      },
    });
  }

  async GetCatalogoMedicamentoViasDeAdministracion() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_vias_de_administracion",
        app: "common",
      },
    });
  }

  async GetCatalogoFormasFarmaceuticas() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_formas_farmaceuticas",
        app: "common",
      },
    });
  }

  async GetCatalogoGruposAlimenticios() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_grupos_alimenticios",
        app: "common",
      },
    });
  }

  async GetCatalogoUnidadesMedida() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_unidades_medida",
        app: "common",
      },
    });
  }

  async GetCatalogoDiscapacidades() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_discapacidades",
        app: "common",
      },
    });
  }

  async GetCatalogoTipoEpisodio() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_tipo_episodio",
        app: "common",
      },
    });
  }

  async GetIngresosFinanzas(
    id_doctor,
    tipo_fecha,
    fecha_ini = 0,
    fecha_end = 0
  ) {
    return axios.get(api, {
      params: {
        rt: "get_ingresos_finanzas",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
        fecha_ini,
        fecha_end,
      },
    });
  }
  async GetIngresosFinanzasCate(
    id_doctor,
    tipo_fecha,
    fecha_ini = 0,
    fecha_end = 0,
    categoria = ""
  ) {
    return axios.get(api, {
      params: {
        rt: "get_ingresos_finanzas_categoria",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
        fecha_ini,
        fecha_end,
        categoria,
      },
    });
  }

  async GetBalance(id_doctor, tipo_fecha) {
    return axios.get(api, {
      params: {
        rt: "get_balance",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
      },
    });
  }

  async GetMedicamentosDoctor(id_doctor, pagina, limite, search, orden) {
    return axios.get(api, {
      params: {
        rt: "get_medicamentos_doctor",
        app: "app_doctor",
        id_doctor,
        pagina,
        limite,
        search,
        orden,
      },
    });
  }

  async GetEstudiosDoctor(id_doctor, pagina, limite, search, orden) {
    return axios.get(api, {
      params: {
        rt: "get_estudios_doctor",
        app: "app_doctor",
        id_doctor,
        pagina,
        limite,
        search,
        orden,
      },
    });
  } 
  async GetIngredientes(id_doctor, pagina, limite, search, orden) {
    return axios.get(api, {
      params: {
        rt: "get_ingredientes",
        app: "app_doctor",
        id_doctor,
        pagina,
        limite,
        search,
        orden,
      },
    });
  }

  async GetBalanceConsultorio(id_doctor, tipo_fecha, id_cons) {
    return axios.get(api, {
      params: {
        rt: "get_balance_consultorio",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
        id_cons,
      },
    });
  }

  async GetGastosProv(id_doctor, tipo_fecha) {
    return axios.get(api, {
      params: {
        rt: "get_gastos_prov",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
      },
    });
  }

  async GetActividadConsultoriosPorMesEnCurso(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_actividad_consultorios_mes_en_curso",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }

  async GetVentas(id_doctor, tipo_fecha) {
    return axios.get(api, {
      params: {
        rt: "get_ingresos_ventas",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
      },
    });
  }

  async GetGrafica(id_doctor, tipo_fecha) {
    return axios.get(api, {
      params: {
        rt: "get_grafica_actividades_balance",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
      },
    });
  }

  async GetGraficaCategoriasGastos(id_doctor, tipo_fecha) {
    return axios.get(api, {
      params: {
        rt: "get_grafica_categorias_gastos",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
      },
    });
  }

  async GetGraficaConsultorio(id_doctor, id_consultorio, tipo_fecha) {
    return axios.get(api, {
      params: {
        rt: "get_grafica_consultorio_balance",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
        tipo_fecha,
      },
    });
  }

  async GetGraficaProveedor(id_doctor, id_proveedor) {
    return axios.get(api, {
      params: {
        rt: "get_grafica_proveedor",
        app: "app_doctor",
        id_doctor,
        id_proveedor,
      },
    });
  }

  async GetMovimietnosRecientes(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_movimientos_recientes",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetMovimietnosRecientesProv(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_movimientos_recientes_prov",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetFormatosComprasDoctor(id_doctor, id_org, pagina, limite) {
    return axios.get(api, {
      params: {
        rt: "get_formatos_compra",
        app: "app_doctor",
        id_doctor,
        id_org,
        pagina,
        limite,
      },
    });
  }

  async GetFormatosComprasDoctorPendiente(id_doctor, id_org) {
    return axios.get(api, {
      params: {
        rt: "get_formatos_compra_pendiente",
        app: "app_doctor",
        id_doctor,
        id_org,
      },
    });
  }

  async GetGastosProvedores(
    id_doctor,
    id_org,
    filtro,
    id_cons,
    pagina,
    limite,
    id_prov = 0
  ) {
    return axios.get(api, {
      params: {
        rt: "get_gastos_proveedor",
        app: "app_doctor",
        id_doctor,
        id_org,
        filtro,
        id_cons,
        pagina,
        limite,
        id_prov,
      },
    });
  }

  async deleteOrdenCompra(id_doctor, id_org, id_oc) {
    return axios.get(api, {
      params: {
        rt: "delete_orden_compra",
        app: "app_doctor",
        id_doctor,
        id_org,
        id_oc,
      },
    });
  }
  async DeleteMedicamentoListado(id_doctor, id) {
    return axios.get(api, {
      params: {
        rt: "delete_medicamento_listado",
        app: "app_doctor",
        id_doctor,
        id
      },
    });
  }
  
  async DeleteIngrediente(id_doctor, id) {
    return axios.get(api, {
      params: {
        rt: "delete_ingredientes",
        app: "app_doctor",
        id_doctor,
        id
      },
    });
  }

  async DeleteEstudioListado(id_doctor, id) {
    return axios.get(api, {
      params: {
        rt: "delete_estudio_listado",
        app: "app_doctor",
        id_doctor,
        id
      },
    });
  }

  async CopiarOrdenCompra(id_oc, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "copiar_orden_compra",
        app: "app_doctor",
        id_doctor,
        id_oc,
      },
    });
  }

  async GetGasto(id_doctor, id_gasto) {
    return axios.get(api, {
      params: {
        rt: "get_gasto",
        app: "app_doctor",
        id_doctor,
        id_gasto,
      },
    });
  }

  async GetChat(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_chat",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetIngreso(id_doctor, id_venta) {
    return axios.get(api, {
      params: {
        rt: "get_ingreso",
        app: "app_doctor",
        id_doctor,
        id_venta,
      },
    });
  }

  async GetGastosFinanzas(
    id_doctor,
    tipo_fecha,
    proveedor = 0,
    fecha_ini = 0,
    fecha_end = 0,
    categoria = "",
    forma_pago = ""
  ) {
    return axios.get(api, {
      params: {
        rt: "get_gastos",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
        proveedor,
        fecha_ini,
        fecha_end,
        categoria,
        forma_pago,
      },
    });
  }

  async GetGastosFinanzasReporte(id_doctor, cont_filtros, pagina, limite) {
    return axios.get(api, {
      params: {
        rt: "get_gastos_reporte",
        app: "app_doctor",
        id_doctor,
        cont_filtros: JSON.stringify(cont_filtros),
        pagina,
        limite,
      },
    });
  }
  async GetGastosAnio(id_doctor, anio) {
    return axios.get(api, {
      params: {
        rt: "get_gastos_anio",
        app: "app_doctor",
        id_doctor,
        anio,
      },
    });
  }

  async GetVacunacionPaciente(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_paciente_vacunacion",
        app: "app_doctor",
        id_paciente,
      },
    });
  }
  // async GetVacunacionRecurrentePaciente(id_paciente) {
  //   return axios.get(api, {
  //     params: {
  //       rt: "get_paciente_vacunacion_recurrente",
  //       app: "app_doctor",
  //       id_paciente,
  //     },
  //   });
  // }

  async GetGastosFinanzasReporteMensual(id_doctor, anio, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_gastos_reporte_mensual",
        app: "app_doctor",
        id_doctor,
        anio,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetBalanceFinanzasReporte(id_doctor, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_balance_reporte",
        app: "app_doctor",
        id_doctor,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }
  async GetBalanceFinanzasReporteAnual(id_doctor, anio, id_cons, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_balance_reporte_anual",
        app: "app_doctor",
        id_doctor,
        anio,
        id_cons,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetIngresosDia(id_doctor, cont_filtros, dia_sel, mes_sel, anio_sel, pagina, limite) {
    return axios.get(api, {
      params: {
        rt: "get_ingresos_dia",
        app: "app_doctor",
        id_doctor,
        cont_filtros: JSON.stringify(cont_filtros),
        dia_sel, mes_sel, anio_sel,
        pagina,
        limite,
      },
    });
  }
  async GetIngresosFinanzasReporte(id_doctor, cont_filtros, pagina, limite) {
    return axios.get(api, {
      params: {
        rt: "get_ingresos_reporte",
        app: "app_doctor",
        id_doctor,
        cont_filtros: JSON.stringify(cont_filtros),
        pagina,
        limite,
      },
    });
  }
  async GetIngresosAnio(id_doctor, anio) {
    return axios.get(api, {
      params: {
        rt: "get_ingresos_anio",
        app: "app_doctor",
        id_doctor,
        anio
      },
    });
  }
  async GetConsultasConsultorioAnio(id_doctor, anio, id_consultorio) {
    return axios.get(api, {
      params: {
        rt: "get_consultas_consultorio_anio",
        app: "app_doctor",
        id_doctor,
        anio,
        id_consultorio,
      },
    });
  }
  async GetConsultasAnio(id_doctor, anio, id_consultorio) {
    return axios.get(api, {
      params: {
        rt: "get_consultas_anio",
        app: "app_doctor",
        id_doctor,
        anio,
        id_consultorio,
      },
    });
  }
  async GetIngresosServicioAnio(id_doctor, anio, id_servicio) {
    return axios.get(api, {
      params: {
        rt: "get_ingresos_anio_servicio",
        app: "app_doctor",
        id_doctor,
        anio,
        id_servicio
      },
    });
  }
  async GetDesglosesVentasAnio(id_doctor, anio) {
    return axios.get(api, {
      params: {
        rt: "get_desgloses_ventas_anio",
        app: "app_doctor",
        id_doctor,
        anio
      },
    });
  }
  async GetIngresosFinanzasReporteAnual(id_doctor, anio, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_ingresos_reporte_anual",
        app: "app_doctor",
        id_doctor,
        anio,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetServiciosProveedor(id_doctor, proveedor) {
    return axios.get(api, {
      params: {
        rt: "get_servicios_proveedor",
        app: "app_doctor",
        id_doctor,
        proveedor,
      },
    });
  }

  async GetPacienteResumen(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_paciente_resumen",
        app: "app_doctor",
        id_paciente,
      },
    });
  }

  async GetEpisodio(id_doctor, id_episodio) {
    return axios.get(api, {
      params: {
        rt: "get_episodio",
        app: "app_doctor",
        id_doctor,
        id_episodio,
      },
    });
  }

  async GetSeccionesEpisodio() {
    return axios.get(api, {
      params: {
        rt: "get_secciones_episodio",
        app: "common"
      },
    });
  }

  async GetItemsMenuDocca() {
    return axios.get(api, {
      params: {
        rt: "get_items_menu_docca",
        app: "common",
      },
    });
  }
  async GetItemsMenuDoccaPaciente() {
    return axios.get(api, {
      params: {
        rt: "get_items_menu_docca_paciente",
        app: "common",
      },
    });
  }
  async GetPermisosUsuario(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_permisos_menu",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetConsultorios(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_consultorios",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }

  async GetConsultoriosSelector(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_consultorios_selector",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }

  async GetConsultoriosExtra(id_doctor, id_organizacion, id) {
    return axios.get(api, {
      params: {
        rt: "get_consultorios_extra",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        id,
      },
    });
  }
  async GetConsultoriosOrden(id_doctor, id_organizacion, orden) {
    return axios.get(api, {
      params: {
        rt: "get_consultorios_orden",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        orden,
      },
    });
  }

  async GetTotalVentasPorConsultorio(id_doctor, id_consultorio) {
    return axios.get(api, {
      params: {
        rt: "get_ventas_por_consultorio",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
      },
    });
  }

  async GetTotalConsultasPorConsultorio(id_doctor, id_consultorio) {
    return axios.get(api, {
      params: {
        rt: "get_total_consultas_por_consultorio",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
      },
    });
  }

  async GetTotalConsultasPorConsultorioFecha(
    id_doctor,
    tipo_fecha,
    id_consultorio
  ) {
    return axios.get(api, {
      params: {
        rt: "get_total_consultas_por_consultorio_fecha",
        app: "app_doctor",
        id_doctor,
        tipo_fecha,
        id_consultorio,
      },
    });
  }

  async GetCajaChicaConsultorio(id_doctor, id_consultorio) {
    return axios.get(api, {
      params: {
        rt: "get_caja_chica_consultorio",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
      },
    });
  }

  async GetProductos(id_doctor, id_consultorio) {
    return axios.get(api, {
      params: {
        rt: "get_productos",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
      },
    });
  }

  async GetProductosTabla(id_doctor, id_consultorio, pagina, limite, filtro_cat) {
    return axios.get(api, {
      params: {
        rt: "get_productos_tabla",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
        pagina,
        limite,
        filtro_cat,
      },
    });
  }

  async GetConsumiblesProductosTabla(id_doctor, id_consultorio, pagina, limite, filtro_cat, search) {
    return axios.get(api, {
      params: {
        rt: "get_consumibles_productos_tabla",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
        pagina,
        limite,
        filtro_cat,
        search,
      },
    });
  }

  async GetProductosConsultorio(id_doctor, id_consultorio) {
    return axios.get(api, {
      params: {
        rt: "get_productos_consultorio",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
      },
    });
  }

  async GetProductoStock(id_producto) {
    return axios.get(api, {
      params: {
        rt: "get_producto_stock",
        app: "app_doctor",
        id_producto,
      },
    });
  }

  async GetConsumibleProductoStock(id_producto) {
    return axios.get(api, {
      params: {
        rt: "get_consumible_producto_stock",
        app: "app_doctor",
        id_producto,
      },
    });
  }

  async GetConsumiblesProductoStock(id_producto) {
    return axios.get(api, {
      params: {
        rt: "get_consumibles_producto_stock",
        app: "app_doctor",
        id_producto,
      },
    });
  }

  async GetProducto(id_doctor, id_prod) {
    return axios.get(api, {
      params: {
        rt: "get_producto",
        app: "app_doctor",
        id_doctor,
        id_prod,
      },
    });
  }

  async GetConsumibleProducto(id_doctor, id_prod) {
    return axios.get(api, {
      params: {
        rt: "get_consumible_producto",
        app: "app_doctor",
        id_doctor,
        id_prod,
      },
    });
  }

  async GetCategoria(id_doctor, id_cat) {
    return axios.get(api, {
      params: {
        rt: "get_categoria",
        app: "app_doctor",
        id_doctor,
        id_cat,
      },
    });
  }

  async GetConsumibleCategoria(id_doctor, id_cat) {
    return axios.get(api, {
      params: {
        rt: "get_consumibles_categoria",
        app: "app_doctor",
        id_doctor,
        id_cat,
      },
    });
  }

  async GetMarca(id_doctor, id_marca) {
    return axios.get(api, {
      params: {
        rt: "get_marca",
        app: "app_doctor",
        id_doctor,
        id_marca,
      },
    });
  }

  async GetConsumibleMarca(id_doctor, id_marca) {
    return axios.get(api, {
      params: {
        rt: "get_consumible_marca",
        app: "app_doctor",
        id_doctor,
        id_marca,
      },
    });
  }

  async PagarNomina(request) {
    return axios.post(api, {
      params: {
        rt: "pagar_nomina",
        app: "app_doctor",
        request,
      },
    });
  }


  async UploadFotoGaleria(request) {
    return axios.post(api, {
      params: {
        rt: "upload_foto_galeria",
        app: "app_doctor",
        request,
      },
    });
  }

  async updateFotoGaleria(request) {
    return axios.post(api, {
      params: {
        rt: "update_foto_galeria",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteFotoGaleria(request) {
    return axios.post(api, {
      params: {
        rt: "delete_foto_galeria",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateAvatarDoctor(request) {
    return axios.post(api, {
      params: {
        rt: "update_avatar_doctor",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateLogotipoReceta(request) {
    return axios.post(api, {
      params: {
        rt: "update_logo_receta",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateLogotipo(request) {
    return axios.post(api, {
      params: {
        rt: "update_logo",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveProducto(request) {
    return axios.post(api, {
      params: {
        rt: "save_producto",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveIngrediente(request) {
    return axios.post(api, {
      params: {
        rt: "save_ingrediente",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateIngrediente(request) {
    return axios.post(api, {
      params: {
        rt: "update_ingrediente",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveConsumibleProducto(request) {
    return axios.post(api, {
      params: {
        rt: "save_consumible_producto",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveToken(request) {
    return axios.post(api, {
      params: {
        rt: "save_token",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveProductoInventario(request) {
    return axios.post(api, {
      params: {
        rt: "save_producto_inventario",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveCategoria(request) {
    return axios.post(api, {
      params: {
        rt: "save_categoria",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveConsumibleCategoria(request) {
    return axios.post(api, {
      params: {
        rt: "save_consumibles_categoria",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveMarca(request) {
    return axios.post(api, {
      params: {
        rt: "save_marca",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveConsumibleMarca(request) {
    return axios.post(api, {
      params: {
        rt: "save_consumible_marca",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveInasistenciaNomina(request) {
    return axios.post(api, {
      params: {
        rt: "save_inasistencia_empleado_nomina",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveInasistencia(request) {
    return axios.post(api, {
      params: {
        rt: "save_inasistencia_empleado",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveHorasExtraEmpleadoNomina(request) {
    return axios.post(api, {
      params: {
        rt: "save_horas_extra_empleado_nomina",
        app: "app_doctor",
        request,
      },
    });
  }
  async SaveHorasExtraEmpleado(request) {
    return axios.post(api, {
      params: {
        rt: "save_horas_extra_empleado",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveOtrosConceptosNominaEmpleado(request) {
    return axios.post(api, {
      params: {
        rt: "save_otros_conceptos_nomina_empleado",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveOtrosConceptosNominaEmpleadoNomina(request) {
    return axios.post(api, {
      params: {
        rt: "save_otros_conceptos_nomina_empleado_nomina",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveImpuestosEmpleadoNomina(request) {
    return axios.post(api, {
      params: {
        rt: "save_impuestos_nomina_empleado_nomina",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateMarca(request) {
    return axios.post(api, {
      params: {
        rt: "update_marca",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConsumibleMarca(request) {
    return axios.post(api, {
      params: {
        rt: "update_consumible_marca",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateCategoria(request) {
    return axios.post(api, {
      params: {
        rt: "update_categoria",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConsumibleCategoria(request) {
    return axios.post(api, {
      params: {
        rt: "update_consumibles_categoria",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateProducto(request) {
    return axios.post(api, {
      params: {
        rt: "update_producto",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConnsumible(request) {
    return axios.post(api, {
      params: {
        rt: "update_consumible",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateProductoStock(request) {
    return axios.post(api, {
      params: {
        rt: "update_producto_stock",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConsumibleProductoStock(request) {
    return axios.post(api, {
      params: {
        rt: "update_consumible_producto_stock",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteProducto(request) {
    return axios.post(api, {
      params: {
        rt: "delete_producto",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteConsumibleProducto(request) {
    return axios.post(api, {
      params: {
        rt: "delete_consumible_producto",
        app: "app_doctor",
        request,
      },
    });
  }

  async deleteArchivoEpisodioPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "delete_archivo_episodio_paciente",
        app: "app_paciente",
        request,
      },
    });
  }
  async deleteEstudioMedicoPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "delete_estudio_medico_paciente",
        app: "app_paciente",
        request,
      },
    });
  }

  async saveEstudioMedicoVacio(request) {
    return axios.post(api, {
      params: {
        rt: "upload_archivo_estudio_medico",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteCategoria(request) {
    return axios.post(api, {
      params: {
        rt: "delete_categoria",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteConsumibleCategoria(request) {
    return axios.post(api, {
      params: {
        rt: "delete_consumible_categoria",
        app: "app_doctor",
        request,
      },
    });
  }
  async DeleteMarca(request) {
    return axios.post(api, {
      params: {
        rt: "delete_marca",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteConsumibleMarca(request) {
    return axios.post(api, {
      params: {
        rt: "delete_consumible_marca",
        app: "app_doctor",
        request,
      },
    });
  }

  async GetCategorias(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_categorias",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetConsumiblesCategorias(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_consumibles_categorias",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetReceta(id_receta) {
    return axios.get(api, {
      params: {
        rt: "get_receta",
        app: "app_doctor",
        id_receta,
      },
    });
  }

  async GetMarcas(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_marcas",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetConsumiblesMarcas(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_consumibles_marcas",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetServicios(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_servicios",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }

  async GetServiciosTabla(id_doctor, id_organizacion, pagina, limite, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_servicios_tabla",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        pagina,
        limite,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetEspecialidadesEpisodios(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_especialidades_episodios",
        app: "app_doctor",
        id_paciente,
      },
    });
  }
  async GetEspecialidadesTratamientos(id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_especialidades_tratamientos",
        app: "app_doctor",
        id_paciente,
      },
    });
  }

  async GetPagosPaciente(id_paciente, id_doctor, id_org, pagina, limite) {
    return axios.get(api, {
      params: {
        rt: "get_pagos_paciente_especialista",
        app: "app_doctor",
        id_paciente,
        id_doctor,
        id_org,
        pagina,
        limite,
      },
    });
  }

  async GetServiciosActivos(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_servicios_activos",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }
  async GetServiciosActivosAgendablesPaciente(id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_servicios_activos_agendables_paciente",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
      },
    });
  }

  async GetServiciosOrdenados(id_doctor, id_organizacion, cont_filtros) {
    return axios.get(api, {
      params: {
        rt: "get_servicios_ordenados",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        cont_filtros: JSON.stringify(cont_filtros),
      },
    });
  }

  async GetServicio(id_doctor, id_servicio) {
    return axios.get(api, {
      params: {
        rt: "get_servicio",
        app: "app_doctor",
        id_doctor,
        id_servicio,
      },
    });
  }

  async GetTotalVentasPorServicio(id_servicio) {
    return axios.get(api, {
      params: {
        rt: "get_total_ventas_por_servicio",
        app: "app_doctor",
        id_servicio,
      },
    });
  }

  async GetVentasPorServicio(id_servicio) {
    return axios.get(api, {
      params: {
        rt: "get_ventas_por_servicio",
        app: "app_doctor",
        id_servicio,
      },
    });
  }

  async GetHorariosDisponiblesDia(id_doctor, tiempo, fecha) {
    return axios.get(api, {
      params: {
        rt: "get_horarios_disponibles_dia",
        app: "app_doctor",
        id_doctor,
        tiempo,
        fecha,
      },
    });
  }

  async GetMinMaxHorarioConsultorio(id_consultorio, id_doctor, tipo_horario) {
    return axios.get(api, {
      params: {
        rt: "get_min_max_horario_consultorio",
        app: "app_doctor",
        id_consultorio,
        id_doctor,
        tipo_horario,
      },
    });
  }

  async GetEventosFechas(id_doctor, id_organizacion, fecha_inicio, fecha_fin) {
    return axios.get(api, {
      params: {
        rt: "get_eventos_doctor_fechas",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        fecha_inicio,
        fecha_fin,
      },
    });
  }

  async GetDisponibilidadDoctor7DiasAgenda(id_doctor, fecha, tiempo_servicio, id_consultorio) {
    return axios.get(api, {
      params: {
        rt: "get_disponibilidad_doctor_7_dias_agenda",
        app: "app_doctor",
        id_doctor,
        fecha,
        tiempo_servicio,
        id_consultorio,
      },
    });
  }

  async GetHorariosDisponiblesDiaPersonal(id_doctor, tiempo, fecha) {
    return axios.get(api, {
      params: {
        rt: "get_horarios_disponibles_dia_personal",
        app: "app_doctor",
        id_doctor,
        tiempo,
        fecha,
      },
    });
  }

  async GetSolicitudesPendientes(id_doctor, id_org, id_cons) {
    return axios.get(api, {
      params: {
        rt: "get_solicitudes_pendientes",
        app: "app_doctor",
        id_doctor,
        id_org,
        id_cons,
      },
    });
  }
  async GetSolicitudesRechazadas(id_doctor, id_org, id_cons) {
    return axios.get(api, {
      params: {
        rt: "get_solicitudes_rechazadas",
        app: "app_doctor",
        id_doctor,
        id_org,
        id_cons,
      },
    });
  }

  async GetEpisodioDiagnosticosCatalogoAutoComplete(term) {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_diagnosticos_autocomplete",
        app: "common",
        term,
      },
    });
  }

  async GetDiagnosticosCatalogo() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_diagnosticos",
        app: "common",
      },
    });
  }

  async GetCatalogoProcedimientos(term) {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_procedimientos_autocomplete",
        app: "common",
        term,
      },
    });
  }

  async GetPacientesAutoComplete(term, id_doctor, id_organizacion) {
    return axios.get(api, {
      params: {
        rt: "get_pacientes_autocomplete",
        app: "app_doctor",
        term,
        id_doctor,
        id_organizacion,
      },
    });
  }

  async GetCatalogoFrecuencias() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_frecuencias",
        app: "app_doctor",
      },
    });
  }

  async GetCalendarioDisponibilidad(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_calendario_disponibilidad",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  // async GetEventosDia(id_doctor, fecha) {
  //   return axios.get(api, {
  //     params: {
  //       rt: "get_eventos_dia",
  //       app: "app_doctor",
  //       id_doctor,
  //       fecha,
  //     },
  //   });
  // }

  async FindBloqueo(id_doctor, fecha) {
    return axios.get(api, {
      params: {
        rt: "find_bloqueo",
        app: "app_doctor",
        id_doctor,
        fecha,
      },
    });
  }

  async GetCatalogoProcedimientosServicios() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_procedimientos_servicios",
        app: "common",
      },
    });
  }

  async GetCatalogoGeneros() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_generos",
        app: "common",
      },
    });
  }

  async GetCatalogoTiposSangre() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_tipo_sangre",
        app: "common",
      },
    });
  }

  async GetCatalogoPrefijos() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_prefijos",
        app: "app_doctor",
      },
    });
  }

  async GetEpisodioResumenPDF(id) {
    return axios.get(api, {
      params: {
        rt: "get_episodio_pdf",
        app: "app_doctor",
        id,
      },
    });
  }

  async GetCatalogoTiposProveedores() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_tipos_proveedores",
        app: "common",
      },
    });
  }

  async GetProveedor(id_doctor, id_proveedor) {
    return axios.get(api, {
      params: {
        rt: "get_proveedor",
        app: "app_doctor",
        id_doctor,
        id_proveedor,
      },
    });
  }
  async GetProveedorBancos(id_doctor, id_proveedor) {
    return axios.get(api, {
      params: {
        rt: "get_proveedor_bancos",
        app: "app_doctor",
        id_doctor,
        id_proveedor,
      },
    });
  }

  async GetProveedorServicio(id_doctor, id_servicio) {
    return axios.get(api, {
      params: {
        rt: "get_proveedor_servicio",
        app: "app_doctor",
        id_doctor,
        id_servicio,
      },
    });
  }

  async GetCatalogoAseguradoras() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_aseguradoras",
        app: "common",
      },
    });
  }
  async GetAseguradorasEspecialista(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_aseguradoras_especialista",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetCatalogoNacionalidades() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_nacionalidades",
        app: "common",
      },
    });
  }

  async GetCatalogoEspecialidades() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_especialidades",
        app: "common",
      },
    });
  }

  async GetCatalogoEspecialidadesArea(id_area) {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_especialidades_area",
        app: "common",
        id_area,
      },
    });
  }

  async GetEspecialidadesDoctor(id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_especialidades_doctor",
        app: "app_doctor",
        id_doctor,
      },
    });
  }

  async GetCatalogoServiciosDuracion() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_servicios_duracion",
        app: "common",
      },
    });
  }

  async GetCatalogoCategoriasServicios() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_categorias_servicio",
        app: "common",
      },
    });
  }

  async GetCatalogoServiciosDuracionDosHoras() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_servicios_duracion_dos_horas",
        app: "common",
      },
    });
  }

  async GetCita(id_doctor, id_cita) {
    return axios.get(api, {
      params: {
        rt: "get_cita",
        app: "app_doctor",
        id_doctor,
        id_cita,
      },
    });
  }
  async GetCitaData(id_cita) {
    return axios.get(api, {
      params: {
        rt: "get_cita_data",
        app: "app_doctor",
        id_cita,
      },
    });
  }

  async ValidateEmailAsistente(email) {
    return axios.get(api, {
      params: {
        rt: "validate_email_asistente",
        app: "auth",
        email,
      },
    });
  }

  async GetCatalogoCodigoPostal(cp) {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_codigo_postal",
        app: "common",
        cp,
      },
    });
  }

  async GetCatalogoEstadoCivil() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_estado_civil",
        app: "common",
      },
    });
  }

  async GetCatalogoPercepciones() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_percepciones",
        app: "common",
      },
    });
  }
  async GetCatalogoDeducciones() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_deducciones",
        app: "common",
      },
    });
  }

  async GetDiscapacidadesCatalagoData() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_discapacidades",
        app: "common",
      },
    });
  }

  async GetParentescoCatalagoData() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_parentescos",
        app: "common",
      },
    });
  }

  async GetCatalogoReligion() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_religiones",
        app: "common",
      },
    });
  }

  async GetCatalogoEntidades() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_entidades",
        app: "common",
      },
    });
  }

  async GetCatalogoLenguaIndigena() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_lenguas_indigenas",
        app: "common",
      },
    });
  }

  async VerificarCodigo(id_verificacion, codigo) {
    return axios.get(api, {
      params: {
        rt: "verificar_codigo",
        app: "auth",
        id_verificacion,
        codigo,
      },
    });
  }
  async enviarSmsVerificacion(request) {
    return axios.post(api, {
      params: {
        rt: "send_sms_registro",
        app: "auth",
        request,
      },
    });
  }

  async SaveHorarioRegistro(request) {
    return axios.post(api, {
      params: {
        rt: "save_horario_registro",
        app: "app_doctor",
        request,
      },
    });
  }
  async SaveHorarioArray(request) {
    return axios.post(api, {
      params: {
        rt: "save_horario_array",
        app: "app_doctor",
        request,
      },
    });
  }

  async TestNotificacionInsertar(request) {
    return axios.post(api, {
      params: {
        rt: "insertar_notificacion",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveAreaMedicaDoctor(request) {
    return axios.post(api, {
      params: {
        rt: "save_area_medica_doctor",
        app: "app_doctor",
        request,
      },
    });
  }

  async VerificarCodigoChangePassword(id_verificacion, codigo) {
    return axios.get(api, {
      params: {
        rt: "verificar_codigo_change_password",
        app: "auth",
        id_verificacion,
        codigo,
      },
    });
  }

  async GetLocation(lat, long) {
    return axios.get(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      long +
      "&key=" +
      API_KEY
    );
  }

  // *############################################################################################
  // *POST
  // *############################################################################################

  async DeleteIngreso(request) {
    return axios.post(api, {
      params: {
        rt: "delete_ingreso",
        app: "app_doctor",
        request,
      },
    });
  }

  async ChangePassword(request) {
    return axios.post(api, {
      params: {
        rt: "change_password_especialista",
        app: "auth",
        request,
      },
    });
  }

  async GetCaptchaScore(request) {
    return axios.post(api, {
      params: {
        rt: "get_captcha_score",
        app: "auth",
        request,
      },
    });
  }

  async RegistroS1(request) {
    return axios.post(api, {
      params: {
        rt: "registro_step1",
        app: "app_doctor",
        request,
      },
    });
  }

  async GetCatalogoLicenciaturas() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_licenciaturas",
        app: "common",
      },
    });
  }

  async GetCatalogoAreasMedicas() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_area_medica",
        app: "common",
      },
    });
  }

  async GetCatalogoPaises() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_paises",
        app: "common",
      },
    });
  }

  async GetCatalogoMaestrias() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_maestrias",
        app: "common",
      },
    });
  }

  async GetCatalogoDoctorados() {
    return axios.get(api, {
      params: {
        rt: "get_catalogo_doctorados",
        app: "common",
      },
    });
  }

  async DeleteGasto(request) {
    return axios.post(api, {
      params: {
        rt: "delete_gasto",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioSintomatologia(request) {
    return axios.post(api, {
      params: {
        rt: "update_epiosdio_sintomatologia",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConfiguracionIniFinAgenda(request) {
    return axios.post(api, {
      params: {
        rt: "update_horario_ini_fin_Agenda",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConfiguracionHorario(request) {
    return axios.post(api, {
      params: {
        rt: "update_configuracion_horario",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConfiguracionConfirmacionCita(request) {
    return axios.post(api, {
      params: {
        rt: "update_configuracion_confirmacion_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConfiguracionCedulaProfesional(request) {
    return axios.post(api, {
      params: {
        rt: "update_configuracion_cedula",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConfiguracionPersonal(request) {
    return axios.post(api, {
      params: {
        rt: "update_configuracion_personal_organizacion",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateDiaLaboralOrganizacion(request) {
    return axios.post(api, {
      params: {
        rt: "update_dia_laboral_organizacion",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConfiguracionAgendaHoraInicioFin(request) {
    return axios.post(api, {
      params: {
        rt: "update_configuracion_agenda_hora_inicio_fin",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionCabeza(request) {
    return axios.post(api, {
      params: {
        rt: "update_epiosdio_exploracion_cabeza",
        app: "app_doctor",
        request,
      },
    });
  }

  async ComenzarEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "comenzar_episodio",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionCuello(request) {
    return axios.post(api, {
      params: {
        rt: "update_epiosdio_exploracion_cuello",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionTorax(request) {
    return axios.post(api, {
      params: {
        rt: "update_epiosdio_exploracion_torax",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeshabilitarPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "deshabilitar_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async HabilitarPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "habilitar_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionAbdomen(request) {
    return axios.post(api, {
      params: {
        rt: "update_epiosdio_exploracion_abdomen",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionGenitales(request) {
    return axios.post(api, {
      params: {
        rt: "update_epiosdio_exploracion_genitales",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveEpisodioDiagnostico(request) {
    return axios.post(api, {
      params: {
        rt: "save_episodio_diagnostico",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioDiagnostico(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_diagnostico",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteEpisodioDiagnostico(request) {
    return axios.post(api, {
      params: {
        rt: "delete_episodio_diagnostico",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteEpisodioDiagnosticos(request) {
    return axios.post(api, {
      params: {
        rt: "delete_episodio_diagnosticos",
        app: "app_doctor",
        request,
      },
    });
  }

  async RegistroPacienteStep1(request) {
    return axios.post(api, {
      params: {
        rt: "registro_paciente_step1",
        app: "app_doctor",
        request,
      },
    });
  }

  async RegistroPacienteMenorExpress(request) {
    return axios.post(api, {
      params: {
        rt: "registro_paciente_menor_express",
        app: "app_doctor",
        request,
      },
    });
  }

  async RegistroPacienteStep1Tutor(request) {
    return axios.post(api, {
      params: {
        rt: "registro_paciente_step1_tutor",
        app: "app_doctor",
        request,
      },
    });
  }

  async RegistroPacienteStep3Menor(request) {
    return axios.post(api, {
      params: {
        rt: "registro_paciente_step3_menor",
        app: "app_doctor",
        request,
      },
    });
  }

  async RegistroPacienteStep2(request) {
    return axios.post(api, {
      params: {
        rt: "registro_paciente_step2",
        app: "app_doctor",
        request,
      },
    });
  }

  async RegistroPacienteStep3(request) {
    return axios.post(api, {
      params: {
        rt: "registro_paciente_step3",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveEpisodioProcedimiento(request) {
    return axios.post(api, {
      params: {
        rt: "save_episodio_procedimiento",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioProcedimiento(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_procedimiento",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteProcedimientoDiagnostico(request) {
    return axios.post(api, {
      params: {
        rt: "delete_episodio_procedimiento",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteProcedimientoManual(request) {
    return axios.post(api, {
      params: {
        rt: "delete_procedimiento_manual",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveEpisodioReceta(request) {
    return axios.post(api, {
      params: {
        rt: "save_episodio_receta",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioReceta(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_receta",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveEpisodioRecetaAbierta(request) {
    return axios.post(api, {
      params: {
        rt: "save_episodio_receta_abierta",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioRecetaAbierta(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_receta_abierta",
        app: "app_doctor",
        request,
      },
    });
  }

  async GenerarReceta(request) {
    return axios.post(api, {
      params: {
        rt: "generar_receta",
        app: "app_doctor",
        request,
      },
    });
  }

  async GenerarRecetaAbierta(request) {
    return axios.post(api, {
      params: {
        rt: "generar_receta_abierta",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteRecetaEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "delete_episodio_receta",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteRecetaAbiertaEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "delete_episodio_receta_abierta",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioImpresionDiagnostica(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_impresion_diagnostica",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveCalendarioCita(
    id_doctor,
    id_organizacion,
    id_paciente,
    id_consultorio,
    servicios,
    fecha,
    id_inicio,
    id_fin,
    link_consulta,
    personal,
    descripcion,
    id_creador,
    tipo_creador,
    status,
    tipo
  ) {
    return axios.post(api, {
      params: {
        rt: "save_calendario_cita",
        app: "app_doctor",
        id_doctor,
        id_organizacion,
        id_paciente,
        id_consultorio,
        servicios,
        fecha,
        id_inicio,
        id_fin,
        link_consulta,
        personal,
        descripcion,
        id_creador,
        tipo_creador,
        status,
        tipo
      },
    });
  }
  async ReagendarCalendarioCita(
    id_reagenda,
    id_doctor,
    id_organizacion,
    id_paciente,
    id_consultorio,
    id_servicio,
    fecha,
    id_inicio,
    id_fin,
    link_consulta,
    personal,
    descripcion,
    id_creador,
    tipo_creador,
    status,
    tipo
  ) {
    return axios.post(api, {
      params: {
        rt: "reagendar_calendario_cita",
        app: "app_doctor",
        id_reagenda,
        id_doctor,
        id_organizacion,
        id_paciente,
        id_consultorio,
        id_servicio,
        fecha,
        id_inicio,
        id_fin,
        personal,
        descripcion,
        id_creador,
        tipo_creador,
        status,
        tipo
      },
    });
  }

  async SaveRetraso(request) {
    return axios.post(api, {
      params: {
        rt: "save_calendario_retraso",
        app: "app_doctor",
        request,
      },
    });
  }
  async SaveRetrasoCitas(request) {
    return axios.post(api, {
      params: {
        rt: "save_retraso_citas",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveCitaPersonal(request) {
    return axios.post(api, {
      params: {
        rt: "save_cita_personal",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveVacaciones(request) {
    return axios.post(api, {
      params: {
        rt: "save_vacaciones",
        app: "app_doctor",
        request,
      },
    });
  }

  async RemoveRetraso(request) {
    return axios.post(api, {
      params: {
        rt: "remove_calendario_retraso",
        app: "app_doctor",
        request,
      },
    });
  }

  async AgendarBloqueoPorFechasCalendario(request) {
    return axios.post(api, {
      params: {
        rt: "save_calendario_bloqueo",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionTalla(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_exploracion_talla",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionPeso(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_exploracion_peso",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionTensionArterial(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_exploracion_tension_arterial",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionFrecuenciaCardiaca(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_exploracion_frecuencia_cardiaca",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionFrecuenciaRespiratoria(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_exploracion_frecuencia_respiratoria",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioExploracionTemperatura(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_exploracion_temperatura",
        app: "app_doctor",
        request,
      },
    });
  }

  async saveConsultorio(request) {
    return axios.post(api, {
      params: {
        rt: "save_consultorio",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConsultorio(request) {
    return axios.post(api, {
      params: {
        rt: "update_consultorio",
        app: "app_doctor",
        request,
      },
    });
  }

  async RemoveBloqueo(request) {
    return axios.post(api, {
      params: {
        rt: "remove_bloqueo",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioPlanTratamiento(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_tratamiento",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioPronostico(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_pronostico",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteConsultorio(request) {
    return axios.post(api, {
      params: {
        rt: "delete_consultorio",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveServicioProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "save_servicio_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePagoServicioProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "save_pago_servicio_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }

  async RetirarEfectivoCajaChica(request) {
    return axios.post(api, {
      params: {
        rt: "retirar_efectivo_caja_chica",
        app: "app_doctor",
        request,
      },
    });
  }

  async IngresarEfectivoCajaChica(request) {
    return axios.post(api, {
      params: {
        rt: "ingresar_efectivo_caja_chica",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePagoOCProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "save_pago_oc_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateServicioProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "update_servicio_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveServicio(request) {
    return axios.post(api, {
      params: {
        rt: "save_servicio",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveConsumiblesServicio(request) {
    return axios.post(api, {
      params: {
        rt: "save_consumibles_servicio",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateServicio(request) {
    return axios.post(api, {
      params: {
        rt: "update_servicio",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteServicio(request) {
    return axios.post(api, {
      params: {
        rt: "delete_servicio",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteProveedorServicio(request) {
    return axios.post(api, {
      params: {
        rt: "delete_servicio_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteGastoProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "delete_gasto_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePaciente(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async getUserVincularPersonal(user) {
    return axios.post(api, {
      params: {
        rt: "get_usuario_vincular_personal",
        app: "app_doctor",
        user,
      },
    });
  }

  async SavePersonal(request) {
    return axios.post(api, {
      params: {
        rt: "save_personal",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePersonalVinculado(request) {
    return axios.post(api, {
      params: {
        rt: "save_personal_vinculado",
        app: "app_doctor",
        request,
      },
    });
  }

  async SetBajaEmpleado(request) {
    return axios.post(api, {
      params: {
        rt: "set_baja_empleado",
        app: "app_doctor",
        request,
      },
    });
  }
  async cancelaAltaEmpleado(request) {
    return axios.post(api, {
      params: {
        rt: "cancela_alta_empleado",
        app: "app_doctor",
        request,
      },
    });
  }
  async cancelaBajaEmpleado(request) {
    return axios.post(api, {
      params: {
        rt: "cancela_baja_empleado",
        app: "app_doctor",
        request,
      },
    });
  }
  async SetAltaEmpleado(request) {
    return axios.post(api, {
      params: {
        rt: "set_alta_empleado",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateStatusEmpleado(request) {
    return axios.post(api, {
      params: {
        rt: "update_status_empleado",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateUsuarioTrabajador(request) {
    return axios.post(api, {
      params: {
        rt: "update_usuario_trabajador",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePasswordTrabajador(request) {
    return axios.post(api, {
      params: {
        rt: "update_password_trabajador",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveCambioSalarialPersonal(request) {
    return axios.post(api, {
      params: {
        rt: "save_cambio_salarial_personal",
        app: "app_doctor",
        request,
      },
    });
  }

  async updatePermisosVisualizacionPersonal(request) {
    return axios.post(api, {
      params: {
        rt: "update_permisos_menu_personal",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePersonal(request) {
    return axios.post(api, {
      params: {
        rt: "update_personal",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateAsistente(request) {
    return axios.post(api, {
      params: {
        rt: "update_asistente",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "save_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }
  async SaveBancoProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "save_banco_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateBancoProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "update_banco_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }
  async DeleteBancoProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "delete_banco_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateProveedorFacturacion(request) {
    return axios.post(api, {
      params: {
        rt: "update_proveedor_facturacion",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveFormatoCompra(request) {
    return axios.post(api, {
      params: {
        rt: "save_formato_compra",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateCostoProdOC(request) {
    return axios.post(api, {
      params: {
        rt: "update_costo_oc_prod",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateCantidadProdOC(request) {
    return axios.post(api, {
      params: {
        rt: "update_cantidad_oc_prod",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePartidaOC(request) {
    return axios.post(api, {
      params: {
        rt: "delete_partida_oc",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveOrdenCompra(request) {
    return axios.post(api, {
      params: {
        rt: "save_orden_compra",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveProductosOC(request) {
    return axios.post(api, {
      params: {
        rt: "save_productos_orden_compra",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveAseguradorasArray(request) {
    return axios.post(api, {
      params: {
        rt: "save_aseguradoras_especialista",
        app: "app_doctor",
        request,
      },
    });
  }

  async updateConfigBasicaPerfilTerminada(request) {
    return axios.post(api, {
      params: {
        rt: "update_config_basica_perfil_terminada",
        app: "app_doctor",
        request,
      },
    });
  }
  async updateConfigBasicaSistemaTerminada(request) {
    return axios.post(api, {
      params: {
        rt: "update_config_basica_sistema_terminada",
        app: "app_doctor",
        request,
      },
    });
  }
  async updateBienvenidaTerminada(request) {
    return axios.post(api, {
      params: {
        rt: "update_bienvenida_terminada",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "update_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteProveedor(request) {
    return axios.post(api, {
      params: {
        rt: "delete_proveedor",
        app: "app_doctor",
        request,
      },
    });
  }

  async AceptarCita(request) {
    return axios.post(api, {
      params: {
        rt: "aceptar_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async ConfirmarCita(request) {
    return axios.post(api, {
      params: {
        rt: "confirmar_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async GuardarEnlaceConsulta(request) {
    return axios.post(api, {
      params: {
        rt: "save_enlace_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async RechazarCita(request) {
    return axios.post(api, {
      params: {
        rt: "rechazar_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteCita(request) {
    return axios.post(api, {
      params: {
        rt: "delete_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async CancelarCita(request) {
    return axios.post(api, {
      params: {
        rt: "cancelar_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async TerminarCita(request) {
    return axios.post(api, {
      params: {
        rt: "terminar_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async CancelarSolicitudCita(request) {
    return axios.post(api, {
      params: {
        rt: "cancelar_solicitud_cita",
        app: "app_doctor",
        request,
      },
    });
  }
  async ConfirmarAsistenciaPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "confirmar_asistencia_cita",
        app: "app_paciente",
        request,
      },
    });
  }

  async ComenzarEpisodioDesdeCita(request) {
    return axios.post(api, {
      params: {
        rt: "comenzar_episodio_desde_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async SolicitarReagendar(request) {
    return axios.post(api, {
      params: {
        rt: "solicitar_reagndar_cita",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveVenta(request) {
    return axios.post(api, {
      params: {
        rt: "save_venta",
        app: "app_doctor",
        request,
      },
    });
  }

  async CobrarVenta(request) {
    return axios.post(api, {
      params: {
        rt: "cobrar_venta",
        app: "app_doctor",
        request,
      },
    });
  }

  async saveGasto(request) {
    return axios.post(api, {
      params: {
        rt: "save_gasto",
        app: "app_doctor",
        request,
      },
    });
  }

  async saveGastoPersonal(request) {
    return axios.post(api, {
      params: {
        rt: "save_gasto_personal",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteAseguradoraPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "delete_aseguradora_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePaciente(request) {
    return axios.post(api, {
      params: {
        rt: "update_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateInfoBasicaPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_basica_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateDireccionPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "update_direccion_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateInfoFacturacionPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_facturacion_paciente",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateInfoFacturacionEspecialista(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_facturacion_Especialista",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateInfoContactoPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_contacto_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateInfoContactoEspecialista(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_contacto_especialista",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateInfoContactoEmergenciaPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_contacto_emergencia_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveAseguradoraPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "save_aseguradora_paciente",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateAseguradoraPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "update_aseguradora_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveDiaHorarioConsultorio(
    id_doctor,
    id_consultorio,
    dia_semana,
    hora_mat_entrada,
    hora_mat_salida,
    hora_ves_entrada,
    hora_ves_salida,
    id_creador,
    tipo_creador
  ) {
    return axios.post(api, {
      params: {
        rt: "save_dia_horario_consultorio",
        app: "app_doctor",
        id_doctor,
        id_consultorio,
        dia_semana,
        hora_mat_entrada,
        hora_mat_salida,
        hora_ves_entrada,
        hora_ves_salida,
        id_creador,
        tipo_creador,
      },
    });
  }

  async AgregrarspecialidadesMedicina(request) {
    return axios.post(api, {
      params: {
        rt: "agregar_especialidades_medicina",
        app: "app_doctor",
        request,
      },
    });
  }

  async GuardarDatosEspecialista(request) {
    return axios.post(api, {
      params: {
        rt: "guardar_datos_especialista",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePerfilRedesSociales(request) {
    return axios.post(api, {
      params: {
        rt: "update_perfil_redes_sociales",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConfiguracionConsulta(request) {
    return axios.post(api, {
      params: {
        rt: "update_configuracion_consulta",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateAsistentePass(request) {
    return axios.post(api, {
      params: {
        rt: "update_password_asistente",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConfiguracionReceta(request) {
    return axios.post(api, {
      params: {
        rt: "update_configuracion_receta",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateConfiguracionLogoReceta(request) {
    return axios.post(api, {
      params: {
        rt: "update_configuracion_logo_receta",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePerfilBiografia(request) {
    return axios.post(api, {
      params: {
        rt: "update_perfil_biografia",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePerfilEspecialidad(request) {
    return axios.post(api, {
      params: {
        rt: "update_perfil_especialidad",
        app: "app_doctor",
        request,
      },
    });
  }

  async CreatePerfilEspecialidad(request) {
    return axios.post(api, {
      params: {
        rt: "create_especialidad_doctor",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateInfoBasicaEspecialista(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_basica_especialista",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdatePerfilGeneral(request) {
    return axios.post(api, {
      params: {
        rt: "update_perfil_general",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePerfilRegistroLaboral(request) {
    return axios.post(api, {
      params: {
        rt: "save_perfil_registro_laboral",
        app: "app_doctor",
        request,
      },
    });
  }

  async EditarPerfilRegistroLaboral(request) {
    return axios.post(api, {
      params: {
        rt: "editar_perfil_registro_laboral",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePerfilRegistroLaboral(request) {
    return axios.post(api, {
      params: {
        rt: "delete_perfil_registro_laboral",
        app: "app_doctor",
        request,
      },
    });
  }
  async DeleteEspecilidadDoctor(request) {
    return axios.post(api, {
      params: {
        rt: "delete_especialidad_doctor",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEpisodioEvolucion(request) {
    return axios.post(api, {
      params: {
        rt: "update_episodio_evolucion",
        app: "app_doctor",
        request,
      },
    });
  }

  async TerminarEpisodio(request) {
    return axios.post(api, {
      params: {
        rt: "terminar_episodio",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteAlergia(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_alergia",
        app: "app_doctor",
        request,
      },
    });
  }
  async SetConsentimientoHistoriaClinica(request) {
    return axios.post(api, {
      params: {
        rt: "set_consentimiento_historia_clinica",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteVacuna(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_vacuna",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteVacunaManual(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_vacuna_manual",
        app: "app_doctor",
        request,
      },
    });
  }
  async SavePacienteVacunaRecurrenteManual(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_vacuna_recurrente_manual",
        app: "app_doctor",
        request,
      },
    });
  }
  async SavePacienteVacunaRecurrente(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_vacuna_recurrente",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdatePacienteVacunaRecurrente(request) {
    return axios.post(api, {
      params: {
        rt: "update_paciente_vacuna_recurrente",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePacienteVacuna(request) {
    return axios.post(api, {
      params: {
        rt: "update_paciente_vacuna",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePacienteVacuna(request) {
    return axios.post(api, {
      params: {
        rt: "delete_paciente_vacuna",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePacienteVacunaDosisCartilla(request) {
    return axios.post(api, {
      params: {
        rt: "delete_paciente_vacuna_dosis_cartilla",
        app: "app_doctor",
        request,
      },
    });
  }
  async DeleteAplicacionVacunaPaciente(request) {
    return axios.post(api, {
      params: {
        rt: "delete_aplicacion_vacuna_paciente",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePacienteAlergia(request) {
    return axios.post(api, {
      params: {
        rt: "delete_paciente_alergia",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePacienteAlergia(request) {
    return axios.post(api, {
      params: {
        rt: "update_paciente_alergia",
        app: "app_doctor",
        request,
      },
    });
  }

  async ConfirmPacienteAlergia(request) {
    return axios.post(api, {
      params: {
        rt: "confirm_paciente_alergia",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteDiscapacidad(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_discapacidad",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePacienteDiscapacidad(request) {
    return axios.post(api, {
      params: {
        rt: "update_paciente_discapacidad",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePacienteDiscapacidad(request) {
    return axios.post(api, {
      params: {
        rt: "delete_paciente_discapacidad",
        app: "app_doctor",
        request,
      },
    });
  }

  async ConfirmPacienteDiscapacidad(request) {
    return axios.post(api, {
      params: {
        rt: "confirm_paciente_discapacidad",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteHeredofamiliar(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_heredofamiliar",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePacienteHeredofamiliar(request) {
    return axios.post(api, {
      params: {
        rt: "update_paciente_heredofamiliar",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePacienteHeredofamiliar(request) {
    return axios.post(api, {
      params: {
        rt: "delete_paciente_heredofamiliar",
        app: "app_doctor",
        request,
      },
    });
  }

  async ConfirmPacienteHeredofamiliar(request) {
    return axios.post(api, {
      params: {
        rt: "confirm_paciente_heredofamiliar",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteAntecedentePatologico(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_antecedente_patologico",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePacienteAntecedentePatologico(request) {
    return axios.post(api, {
      params: {
        rt: "update_paciente_antecedente_patologico",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePacienteAntecedentePatologico(request) {
    return axios.post(api, {
      params: {
        rt: "delete_paciente_antecedente_patologico",
        app: "app_doctor",
        request,
      },
    });
  }

  async ConfirmPacienteAntecedentePatologico(request) {
    return axios.post(api, {
      params: {
        rt: "confirm_paciente_antecedente_patologico",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteAntecedenteNoPatologico(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_antecedente_no_patologico",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteAntecedenteNoPatologicoFuma(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_antecedente_no_patologico_fuma",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteAntecedenteNoPatologicoToma(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_antecedente_no_patologico_toma",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePacienteAntecedenteNoPatologicoDrogas(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_antecedente_no_patologico_drogas",
        app: "app_doctor",
        request,
      },
    });
  }
  async SavePacienteNoPatologico(request) {
    return axios.post(api, {
      params: {
        rt: "save_paciente_no_patologico",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePacienteNoPatologico(request) {
    return axios.post(api, {
      params: {
        rt: "update_paciente_no_patologico",
        app: "app_doctor",
        request,
      },
    });
  }
  async DeleteNoPatologico(request) {
    return axios.post(api, {
      params: {
        rt: "delete_paciente_no_patologico",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePerfilAseguradora(request) {
    return axios.post(api, {
      params: {
        rt: "delete_perfil_aseguradora",
        app: "app_doctor",
        request,
      },
    });
  }

  async AsignarPerfilAseguradora(request) {
    return axios.post(api, {
      params: {
        rt: "save_perfil_aseguradora",
        app: "app_doctor",
        request,
      },
    });
  }

  async Registro(request) {
    return axios.post(api, {
      params: {
        rt: "registro",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveEpisodioMedicamentosPreviosyActuales(request) {
    return axios.post(api, {
      params: {
        rt: "save_episodio_medicamentos_previos_actuales",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveMedicamentoLista(request) {
    return axios.post(api, {
      params: {
        rt: "save_medicamento_lista",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateMedicamentoLista(request) {
    return axios.post(api, {
      params: {
        rt: "update_medicamento_lista",
        app: "app_doctor",
        request,
      },
    });
  }
  async SaveEstudioLista(request) {
    return axios.post(api, {
      params: {
        rt: "save_estudio_lista",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdateEstudioLista(request) {
    return axios.post(api, {
      params: {
        rt: "update_estudio_lista",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteEpisodioMedicamentosPreviosyActuales(request) {
    return axios.post(api, {
      params: {
        rt: "delete_episodio_medicamentos_previos_actuales",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteRecetasEpisodiosTodas(request) {
    return axios.post(api, {
      params: {
        rt: "delete_episodio_recetas_todas",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteProcedimientosEpisodiosTodos(request) {
    return axios.post(api, {
      params: {
        rt: "delete_episodio_procedimientos_todas",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteRecetasAbiertasEpisodiosTodas(request) {
    return axios.post(api, {
      params: {
        rt: "delete_episodio_recetas_abiertas_todas",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveInfoAcademicaEdu(request) {
    return axios.post(api, {
      params: {
        rt: "save_info_academica_edu",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateInfoAcademicaEdu(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_academica_edu",
        app: "app_doctor",
        request,
      },
    });
  }

  async updateInfoAcademicaDefault(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_academica_default",
        app: "app_doctor",
        request,
      },
    });
  }

  async getAseguradoraPacienteId(id, id_paciente) {
    return axios.get(api, {
      params: {
        rt: "get_aseguradora_paciente_id",
        app: "app_doctor",
        id,
        id_paciente,
      },
    });
  }

  async getRegistroAcademicoById(id, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_registro_Academico_id",
        app: "app_doctor",
        id,
        id_doctor,
      },
    });
  }

  async getAsociacionById(id, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_asociacion_id",
        app: "app_doctor",
        id,
        id_doctor,
      },
    });
  }

  async getRegistroLaboralById(id, id_doctor) {
    return axios.get(api, {
      params: {
        rt: "get_registro_Laboral_id",
        app: "app_doctor",
        id,
        id_doctor,
      },
    });
  }

  async SaveInfoAcademicaInv(request) {
    return axios.post(api, {
      params: {
        rt: "save_info_academica_inv",
        app: "app_doctor",
        request,
      },
    });
  }
  async SaveAsociacion(request) {
    return axios.post(api, {
      params: {
        rt: "save_asociaciones",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdatePerfilRegistroLaboral(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_laboral",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateInfoAcademicaInv(request) {
    return axios.post(api, {
      params: {
        rt: "update_info_academica_inv",
        app: "app_doctor",
        request,
      },
    });
  }
  async UpdateAsociacion(request) {
    return axios.post(api, {
      params: {
        rt: "update_asociacion",
        app: "app_doctor",
        request,
      },
    });
  }
  async SaveInfoAcademicaCur(request) {
    return axios.post(api, {
      params: {
        rt: "save_info_academica_cur",
        app: "app_doctor",
        request,
      },
    });
  }

  async SavePerfilRegistroAcademico(request) {
    return axios.post(api, {
      params: {
        rt: "save_perfil_registro_academico",
        app: "app_doctor",
        request,
      },
    });
  }

  async UpdatePerfilRegistroAcademico(request) {
    return axios.post(api, {
      params: {
        rt: "update_perfil_registro_academico",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveOrganizacionRegistro(request) {
    return axios.post(api, {
      params: {
        rt: "save_organizacion",
        app: "app_doctor",
        request,
      },
    });
  }

  async SaveOrganizacionIndRegistro(request) {
    return axios.post(api, {
      params: {
        rt: "save_organizacion_individual",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeletePerfilRegistroAcademico(request) {
    return axios.post(api, {
      params: {
        rt: "delete_perfil_registro_academico",
        app: "app_doctor",
        request,
      },
    });
  }

  async DeleteAsociacion(request) {
    return axios.post(api, {
      params: {
        rt: "delete_asociacion",
        app: "app_doctor",
        request,
      },
    });
  }
}

// ============================================================

//   async SaveEpisodioMedicamentosAdministradosDuranteLaAtencion(request) {
//     return axios.post(
//       `${api}/especialista/episodio/save/medicamentos-administrados-durante-la-atencion/`,
//       {
//         request: request,
//       }
//     );
//   }

//   async DeleteEpisodioMedicamentoAdministradoDuranteLaAtencion(request) {
//     return axios.post(
//       `${api}/especialista/episodio/delete/medicamentos-administrados-durante-la-atencion/`,
//       {
//         request: request,
//       }
//     );
//   }

// ------------- SIN USAR ----------------

//   async PonerPacienteSeguimiento(request) {
//     return axios.post(`${api}/especialista/pacientes/seguimiento/poner`, {
//       request: request,
//     });
//   }

//   async QuitarPacienteSeguimiento(request) {
//     return axios.post(`${api}/especialista/pacientes/seguimiento/quitar`, {
//       request: request,
//     });
//   }
